import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import Layout from '../components/Layout';
import MarginTopContainer from '../components/MarginTopContainer';
import SEO from '../components/SEO';
import { InfoSection } from '../sections/common';
import { DetailSection } from '../sections/DetailPage';
import { constructBikeTitle } from '../utils/constructBikeTitle';

type DataProps = Record<string, unknown>;

const DetailPage: FC<PageProps<DataProps>> = props => {
  const { pageContext } = props;
  const title = constructBikeTitle(pageContext.bikeDetail);

  return (
    <Layout>
      <SEO
        title={`${title} - Půjčovna kol BeRider`}
        banner={pageContext.bikeDetail.mainImg}
        desc={pageContext.bikeDetail.description}
      />

      <MarginTopContainer>
        <DetailSection bikeDetail={pageContext.bikeDetail} />
        <InfoSection />
      </MarginTopContainer>
    </Layout>
  );
};

export default DetailPage;
