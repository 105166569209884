import React, { FC } from 'react';
import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ShowMoreContainer from '../../components/ShowMoreContainer';
import { BlackButton } from '../../components/Button';
import { Bike } from '../../types/Bike';
import { constructBikeTitle } from '../../utils/constructBikeTitle';
import createMarkup from '../../utils/createMarkup';

type DataProps = {
  bikeDetail: Bike;
};

const useStyles = makeStyles((theme: Theme) => ({
  rootImage: {
    width: '100%',
    height: 'auto',
  },
  rootInfoBox: {
    background: theme.palette.grey[100],
    padding: theme.spacing(2),
  },
  fontBolder: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  rootTechParam: {
    '& .specs.base-specs': {
      '& > span': {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'block',
        marginBottom: theme.spacing(1),
      },

      '& > ul': {
        listStyle: 'none',
        paddingLeft: 0,

        '& > li': {
          padding: theme.spacing(0.5),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          '& > span': {
            '&:first-child': {
              flex: 3,
              textAlign: 'right',
              paddingRight: theme.spacing(1),
              fontWeight: theme.typography.fontWeightMedium,
            },

            '&:last-child': {
              flex: 5,
              textAlign: 'left',
              paddingLeft: theme.spacing(1),
            },
          },
        },
      },

      '& > ul > li > span.name': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },

    '& .tab-content.tab-specification': {
      '& > span': {
        fontSize: theme.typography.h6.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
        display: 'block',
        marginBottom: theme.spacing(1),
      },

      '& .row.spec': {
        padding: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& > div': {
          '&:first-child': {
            flex: 3,
            textAlign: 'right',
            paddingRight: theme.spacing(1),
            fontWeight: theme.typography.fontWeightMedium,
          },

          '&:last-child': {
            flex: 5,
            textAlign: 'left',
            paddingLeft: theme.spacing(1),
          },
        },
      },
    },
  },
}));

const RESPONSIVE_CONTAINER_JUSTIFY = ['center', 'center', 'space-between'];

const DetailSection: FC<DataProps> = props => {
  const classes = useStyles();
  const {
    bikeDetail,
    bikeDetail: { description, mainImg, price24h, techParams },
  } = props;
  const title = constructBikeTitle(bikeDetail);

  return (
    <Container>
      <Box justifyContent={RESPONSIVE_CONTAINER_JUSTIFY} mb={5} clone>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={10} md={6} lg={5}>
            <Box mb={7}>
              <img src={mainImg} alt={title} className={classes.rootImage} />
            </Box>

            <Box mb={3} textAlign="center">
              <BlackButton
                component={Link}
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=tgzrJjDRokuKtdDXmTrDG7xDVz4HycRIojWK05GGyQZUQUM0RVcwU0VKQkZJQ0RZUElURVJVNk43VS4u"
                target="_blank"
                rel="noopener noreferrer"
                isSkewed
                size="large"
              >
                Půjčit kolo
              </BlackButton>
            </Box>
          </Grid>

          <Grid item xs={12} sm={10} md={6} lg={6}>
            <Box mb={3}>
              <Typography component="h1" variant="h4">
                {title}
              </Typography>
            </Box>

            <Box mb={2}>
              <Typography component="div" variant="body1">
                {description}
              </Typography>
            </Box>

            <Box className={classes.rootInfoBox}>
              {/*<Typography*/}
              {/*  component="div"*/}
              {/*  color="primary"*/}
              {/*  variant="h6"*/}
              {/*  gutterBottom*/}
              {/*>*/}
              {/*  Dostupné&nbsp;{units}&nbsp;ks*/}
              {/*</Typography>*/}

              <Typography
                component="div"
                color="inherit"
                variant="h6"
                gutterBottom
              >
                Cena&nbsp;{price24h}&nbsp;Kč/den&nbsp;*
              </Typography>

              <Typography component="div" color="inherit" variant="caption">
                *&nbsp;Vratná kauce je 5000 Kč za kolo z důvodu případných oprav
                při poškození.
              </Typography>
              <Typography component="div" color="inherit" variant="caption">
                *&nbsp;Z důvodu ověření totožnosti a účelů platby je nutné
                mít&nbsp;
                <span className={classes.fontBolder}>
                  schválený účet v BeRideru
                </span>
                .
              </Typography>
            </Box>

            <Box my={5}>
              <Typography component="div" variant="h6">
                Technická specifikace
              </Typography>
              <ShowMoreContainer>
                <div
                  className={classes.rootTechParam}
                  dangerouslySetInnerHTML={createMarkup(techParams)}
                />
              </ShowMoreContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DetailSection;
