import React, { FC, PropsWithChildren, useState } from 'react';
import { Box, Collapse } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '../Button';

type DataProps = Record<string, unknown>;

const useStyles = makeStyles((theme: Theme) => ({
  rootCollapse: {
    position: 'relative',
  },
  rootGradientOverlayDiv: {
    backgroundImage: `linear-gradient(to bottom, transparent 0, ${theme.palette.common.white} 100%)`,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '100px',
  },
}));

const ShowMoreContainer: FC<PropsWithChildren<DataProps>> = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Collapse
        className={classes.rootCollapse}
        in={open}
        collapsedHeight={400}
      >
        {children}
        {!open && <div className={classes.rootGradientOverlayDiv} />}
      </Collapse>
      <Box textAlign="center">
        <Button color="primary" onClick={toggleOpen}>
          Zobrazit&nbsp;{open ? 'méně' : 'více'}
        </Button>
      </Box>
    </div>
  );
};

export default ShowMoreContainer;
